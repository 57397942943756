<template>
  <main class="v-login">
    <Topbar topbar-component="TopbarLogin" />

    <div class="v-login__wrapper">
      <img
        src="/themes/synopsis/assets/login_view.png"
        alt=""
        class="v-login__img"
      />

      <div class="v-login__text">
        <div v-html="courseDescription"></div>

        <div class="v-login__actions">
          <TheButton @click="login" :text="true" class="v-login-login__btn">{{ $t("LOGIN") }}</TheButton>
          <TheButton class="v-login-access__btn" to="/">{{ $t("LOGIN_GET_ACCESS") }}</TheButton>
        </div>
      </div>
    </div>
      <Footer />
  </main>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuth, useTenant } from '@/core'
import { useHead } from '@vueuse/head'
import { useRouter } from 'vue-router'

import Footer from '@/components/Footer'
import Topbar from '@/components/Topbar'
import TheButton from '@/components/TheButton'

export default {
  name: 'Login',

  components: {
    Footer,
    TheButton,
    Topbar,
  },

  setup() {
    const { login, instance } = useAuth()
    const { tenant } = useTenant()
    const router = useRouter()
    const { locale } = useI18n()
    const courseDescription = computed(
      () =>
        tenant.value.welcomeMessage && tenant.value.welcomeMessage[locale.value],
    )

    useHead({
      title: `Login - ${tenant.value.name}`,
    })

    onMounted(async () => {
      try {
        await instance().checkToken()
        router.push('/')
      } catch {
        // Do nothing
      }
    })

    return {
      login,
      courseDescription,
    }
  },
}
</script>
